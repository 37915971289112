<template>
  <b-container
    class="py-3"
    fluid
  >
    <b-row align-h="center">
      <b-col
        class="d-flex flex-column flex-md-row align-items-center text-center"
      >
        <img
          src="@/assets/logo.png"
          class="logo mx-auto mx-md-0"
          alt="Logo"
        >
        <h1 class="title">Asignación de Servicios Funerarios</h1>
      </b-col>
      <b-form
        class="m-3"
        @submit="onSubmitLogOut"
      >
        <b-spinner
          v-if="loadingLogOut"
          small
        />
        <b-button
          v-else
          type="submit"
          variant="dark"
        >
          Cerrar Sesión
        </b-button>
      </b-form>
    </b-row>
  </b-container>
</template>

<script>
import Cookies from "js-cookie";
import apiFelberg from "@/api";

export default {
  data() {
    return {
      loadingLogOut: false
    }
  },
  methods: {
    async onSubmitLogOut(event) {
      this.loadingLogOut = true
      event.preventDefault()

      try {
        const response = await apiFelberg.post('/logout')

        if(response.status === 200){
          Cookies.remove('token')
          await this.$router.push({name: 'login'})
        }
      } catch (error){
        // console.error(error)
      }

      this.loadingLogOut = false
    }
  }
}
</script>

<style>
.logo {
  height: 60px;
  width: 200px;
  object-fit: contain;
}
.title {
  font-size : clamp(1rem, 5vw, 2.5rem);
}
</style>
