<template>
  <b-button

    class="ml-auto"
    variant="outline-dark"
    title="Limpiar"
    @click="clear"
  >

    <span
      class="glyphicon glyphicon-align-left"
      aria-hidden="true"
    /> X
  </b-button>
</template>


<script>
let clickable;
export default {
  name: "ClearButton.vue",
  props:{
    clickable
  },
  emits: ['on-clear-button-click'],
  methods: {
    clear: function () {

      this.$emit('on-clear-button-click')

    }
  }
}
</script>

<style scoped>

</style>
