<template>
  <b-button
    :disabled="searchStatus"
    class="ml-auto"
    variant="danger"
    type="submit"
    @click="search"
  >
    <b-spinner
      v-if="searchStatus"
      small
    />
    <span v-else>Buscar</span>
  </b-button>
</template>

<script>
let searchStatus;
export default {

  name: "SearchButton",
  props: {
    searchStatus: searchStatus
  },
  emits: ['on-search-button-click'],
  data() {
    return {
      loaded: true,
    }
  },
  computed: {
    loading: function () {

      if (this.searchStatus === false && this.loaded === false)  {
        return true;
      }

      return this.searchStatus;
    }
  },
  methods: {
    search: function () {
      this.loaded = false;
      this.$emit('on-search-button-click')

    }
  }

}
</script>

<style scoped>

</style>
