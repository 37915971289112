<template>
  <b-card
    fluid
    bg-variant="primary"
    class="rounded-0 text-center"
  >
    <b-card-text>Supervisor:</b-card-text>
    <b-card-title v-if="supervisorName">
      <template v-if="identifier">
        {{ identifier }} -
      </template>
      {{ supervisorName }}
    </b-card-title>
    <b-row>
      <b-col>
        <b-card-text v-if="phone">
          <svg
            data-v-41be6633=""
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="phone"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-phone mx-auto b-icon bi"
          >
            <g data-v-41be6633="">
              <path
                d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
              />
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
            </g>
          </svg>
          <!-- telefono-->
          {{ phone }}
        </b-card-text>
      </b-col>
      <b-col>
        <b-card-text v-if="email">
          <svg
            data-v-41be6633=""
            viewBox="0 0 16 16"
            width="1em"
            height="1em"
            focusable="false"
            role="img"
            aria-label="envelope"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="bi-envelope mx-auto b-icon bi"
          >
            <g data-v-41be6633="">
              <path
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
              />
            </g>
          </svg>
          <!-- email -->
          {{ email }}
        </b-card-text>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import checkNested from "@/helpers/checkNested";
import filterAndConcatJsonValues from "@/helpers/filterAndConcatJsonValues";

let supervisor;
export default {
  name: "SupervisorCard",
  props: {
    supervisor

  },
  computed: {
    identifier(){
      return checkNested(this,'supervisor','identifier') ? this.supervisor.identifier : "";
    },
    supervisorName:function(){
      let value = "";
      if (checkNested(this,'supervisor','thirdName')){
        value = this.supervisor.thirdName;
      }
      return value;
    },
    phone:function(){
      let value = "";

      if (this.checkNested(this,'supervisor','third','contact','data_set')){
        value = this.filterAndConcatJsonValues(' / ','notification_phone',this.supervisor.third.contact.data_set);
      }

      return value;
    },
    email:function(){
      let value = "";

      if (this.checkNested(this,'supervisor','third','contact','data_set')){
        value = this.filterAndConcatJsonValues('; ','notification_email',this.supervisor.third.contact.data_set);
      }

      return value;
    }
  },

  methods: {
    checkNested,
    filterAndConcatJsonValues
  }
}
</script>

<style scoped>

</style>
