<template>
  <b-card
    align="center"
    header-border-variant="0"
    header-class="px-0 py-1"
    body-border-variant="dark"
    no-body
    header-tag="header"
    class="card"
  >
    <template #header>
      <div class="card-header text-center p-0 border-0">
        <h3
          class="bg-primary rounded-circle mx-auto py-2 px-3"
          style="display: inline-block"
        >
          <strong>{{ (resultIndex + 1).toString() }}</strong>
        </h3>
      </div>
    </template>
    <b-card-text class="bg-secondary py-2">Tanatorio:</b-card-text>
    <b-card-text
      v-if="tanatoryName !== null && tanatoryName !== ''"
    >
      <strong>{{ tanatoryName }}</strong>
    </b-card-text>
    <b-card-text v-else><strong>Sin Tanatorio Asignado</strong></b-card-text>
    <b-card-text class="bg-secondary py-2">Funeraria:</b-card-text>
    <b-card-text
      v-if="funeraryName !== null && funeraryName !== ''"
    >
      <strong>{{ funeraryName }}</strong>
    </b-card-text>
    <b-card-text v-else><strong>Sin Funeraria Asignada</strong></b-card-text>
    <b-card-text>
      <svg
        data-v-41be6633=""
        viewBox="0 0 16 16"
        width="1em"
        height="1em"
        focusable="false"
        role="img"
        aria-label="phone"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi-phone mx-auto b-icon bi"
      >
        <g data-v-41be6633="">
          <path
            d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"
          />
          <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
        </g>
      </svg>
      {{ phone }}
    </b-card-text>
    <b-card-text class="mb-0 pb-3">
      <svg
        data-v-41be6633=""
        viewBox="0 0 16 16"
        width="1em"
        height="1em"
        focusable="false"
        role="img"
        aria-label="envelope"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        class="bi-envelope mx-auto b-icon bi"
      >
        <g data-v-41be6633="">
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"
          />
        </g>
      </svg>
      {{ email }}
    </b-card-text>


    <div
      v-if="notes.show"
      class="d-flex notes p-2"
    >
      <b-avatar
        class="mr-2"
        :src="require('@/assets/icons/information.svg')"
        size="3rem"
        variant="transparent"
      />
      <div>
        <p
          v-for="(note, index) in notes.list"
          :key="`note-${index}`"
          class="text-left small mb-0"
        >
          <b-icon icon="dash" />
          {{ note }}
        </p>
      </div>
    </div>
  </b-card>
</template>

<script>

import filterAndConcatJsonValues from "@/helpers/filterAndConcatJsonValues";
import checkNested from "@/helpers/checkNested";

let resultIndex;
let tanatory;
let funerary;
let contact;
export default {
  name: "TanatoryCard",
  props: {
    resultIndex,
    tanatory,
    funerary,
    contact,

  },
  computed:{
    tanatoryName: function () {
      let value = "";
      if (this.checkNested(this,'tanatory','thirdName')){
        value = this.tanatory.thirdName;
      }
      return value;
    },
    funeraryName: function () {
      let value = "";
      if (this.checkNested(this,'funerary','thirdName')){
        value = this.funerary.thirdName;
      }
      return value;
    },
    email:function(){
      let value = "";

      if (this.checkNested(this,'contact','data_set')){
        value = this.filterAndConcatJsonValues('; ','notification_email',this.contact.data_set);
      }
      if (value===""){
        value = '¡Avisar al Supervisor!'
      }
      return value;
    },
    phone:function(){
      let value = "";

      if (this.checkNested(this,'contact','data_set')){
        value = this.filterAndConcatJsonValues(' / ','notification_phone',this.contact.data_set);
      }

      if (value===""){
        value = '¡Avisar al Supervisor!'
      }

      return value;
    },
    notes(){
      const notes = this.contact.notes.map(note => note.value)

      return {
        show: notes.length,
        list: notes
      }
    },
  },
  methods:{
    checkNested,
    filterAndConcatJsonValues
  }
};
</script>

<style scoped>
.card,
.card .notes {
  height: 100%;
}

.card .notes {
  background-color: #fffeda;
}
</style>
