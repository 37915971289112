export default function (glue,filter,data_set) {
let key;
let value = [];

for (key in data_set){
  if  ( key.search(filter)>-1 ){
    value.push(data_set[key]);
  }
}
return value.join(glue);
}
