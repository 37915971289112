import axios from 'axios'
import Cookies from "js-cookie";

/* eslint-disable */
const baseURL = process.env.VUE_APP_BASE_API_PATH
/* eslint-enable */

const apiFelberg = axios.create({
  baseURL,
})

apiFelberg.interceptors.request.use((config) => {
  const token = Cookies.get('token')
  if(token){
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

apiFelberg.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.href = '/login';
      Cookies.remove('token')
    }
    return Promise.reject(error);
  });

export default apiFelberg
