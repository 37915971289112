<template>
  <div class="dropdown">
    <input
      v-if="Object.keys(selectedItem).length === 0"
      ref="dropdowninput"
      v-model.trim="inputValue"
      :placeholder="placeHolder"
      class="dropdown-input"
      type="text"
      @keyup="getList()"
      @keydown.enter.prevent="onKeyPress"
      @keydown.tab.prevent="onKeyPress"
    >

    <div
      v-else
      class="dropdown-selected"
      @click="resetSelection"
    >
      {{ placeHolder }}: <span class="dropdown-selected-text">{{
        selectedItem[fieldToDisplay]
      }}</span>
    </div>
    <div
      v-if="loading"
      class="dropdown-list text-center"
    >
      <b-spinner small />
    </div>
    <div
      v-if="(inputValue && dataLoaded && inputValue.length >= charLengthThreshold) || wildcard"
      class="dropdown-list"
    >
      <div
        v-for="item in itemListFiltered"
        :key="item.id"
        class="dropdown-item"
        @click="selectItem(item)"
      >
        {{ item[fieldToDisplay] }}
      </div>
    </div>

  </div>
</template>

<script>
import apiFelberg from "@/api";
import concatJsonValues from "@/helpers/concatJsonValues";

let apiUrl;
let placeHolder;
let charLengthThreshold;
let fieldToDisplay;
let accentSensitive;
let clearAble
export default {
  props: {
    apiUrl,
    placeHolder,
    charLengthThreshold,
    fieldToDisplay,
    accentSensitive,
    clearAble,
    wildcard: {
      type: Boolean,
      default: false
    }
  },
  emits: ['on-item-selected', 'on-item-reset', 'select-default-result', 'update-wildcard'],
  data() {
    return {
      selectedItem: {},
      inputValue: '',
      itemList: [],
      dataLoaded: false,
      lastInputValue: '',
      requestTriggeringInputValue: '',
      loading: false

    }
  },
  computed: {
    itemListFiltered(){
      const items = []

      this.itemList.forEach(item => {
        if(this.itemVisible(item, this.accentSensitive) || this.wildcard){
          items.push(item)
        }
      })

      return this.orderByPostalOrder(items)
    }
  },
  watch: {
    'clearAble': function () {
      if (this.clearAble) {
        this.resetSelection();
      }
    },
    inputValue(){
      if(this.inputValue && this.wildcard) {
        this.$emit('update-wildcard', false)
      }
    }
  },
  mounted() {
    //this.getList()
  },
  methods: {
    onKeyPress(event){
      if ((event.keyCode ===9 || event.keyCode ===13) && this.itemListFiltered.length ===1){
        this.selectItem(this.itemListFiltered[0])
      }
    },
    concatJsonValues,
    resetSelection() {

      this.selectedItem = {}
      this.inputValue = '';
      //this.itemList = []
      //this.dataLoaded = false;
      this.$nextTick(() => this.$refs.dropdowninput.focus())
      this.$emit('on-item-reset')
    },
    selectItem(theItem) {

      this.selectedItem = theItem
      this.inputValue = ''

      this.$emit('on-item-selected', theItem)
    },
    itemVisible(item, accentSensitive) {
      let currentName = item[this.fieldToDisplay].toLowerCase()
      let currentInput = this.inputValue.toLowerCase()


      if (accentSensitive === false) {

        currentName = item[this.fieldToDisplay].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
        currentInput = this.inputValue.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()
      }

      return currentName.includes(currentInput)
    },
    isLengthDecreasing(lastLength, currentLength) {
      return currentLength < lastLength;
    },
    canPerformRequestForList() {
      return (
        this.inputValue.length >= this.charLengthThreshold &&
        this.requestTriggeringInputValue !== this.inputValue.slice(0, this.charLengthThreshold)
      );
    },
    getList() {
      if (this.canPerformRequestForList() || this.wildcard) {
        this.loading = true;
        this.requestTriggeringInputValue = this.inputValue.slice(0, this.charLengthThreshold);
        apiFelberg.post(this.apiUrl + '/' + this.requestTriggeringInputValue).then(response => {
          this.itemList = response.data.data
          this.dataLoaded = true
          this.loading = false;

          if(response.data.data.length === 1){
            const item = response.data.data[0]
            this.$emit('select-default-result', item)
            this.selectedItem = item
            this.inputValue = ''
          }
        });
      }

    },
    orderByPostalOrder(data) {
      return data.sort((a, b) => {
        const postalOrderA = a.postal_order ? parseInt(a.postal_order, 10) : 0;
        const postalOrderB = b.postal_order ? parseInt(b.postal_order, 10) : 0;

        return postalOrderA - postalOrderB;
      });
    }
  },


}
</script>

<style>
.dropdown {
  position: relative;
  width: 100%;
  max-width: 400px;
  #margin: 0 auto;
  #margin-bottom: 0.5rem;
}

.dropdown-input, .dropdown-selected {
  width: 100%;
  padding: 5px 8px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}

.dropdown-input:focus, .dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {

  cursor: pointer;
}

.dropdown-selected-text {
  font-weight: bold;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  z-index: 1000;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #edf2f7;
}

.dropdown-item-flag {
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}
</style>
