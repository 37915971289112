

function checkNested(obj, level,  ...rest) {

  if (obj == null) return false

  // eslint-disable-next-line no-prototype-builtins
  if (  rest.length == 0 && obj.hasOwnProperty(level)) return true

  return checkNested(obj[level], ...rest)
}
export default checkNested;
