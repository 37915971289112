<template>
  <div>
    Hay una nueva actualizacion de esta app
    <b-button
      size="lg"
      @click="$emit('update-app',$event)"
    >
      Actualizar
    </b-button>
  </div>
</template>

<script>
export default {
  name: "updateAppButton",
  emits: ['update-app']
}
</script>

<style scoped>

</style>
