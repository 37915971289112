export default function(glue, keys, data_set) {
    let key;
    let value = [];

    for (key in keys) {
        // eslint-disable-next-line no-prototype-builtins
        if (data_set.hasOwnProperty(key)) {
            value.push(data_set[key]);
        }
    }
    return value.join(glue);
}