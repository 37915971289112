<template>

  <div
    id="app"
    class="main-container"
  >
    <header-app />

    <!-- ------------- search elements container----- -->
    <b-container
      class="search-form bg-secondary p-3"
      fluid
    >
      <b-row>
        <b-col cols="5">
          <dynamic-searchable-drop-down
            ref="place_group_dropdow"
            :clear-able="clearStatus"
            :api-url="placeGroupApiPath"
            char-length-threshold="3"
            field-to-display="postal_code"
            place-holder="Codigo Postal"
            @on-item-selected="placeGroupSelected($event)"
            @on-item-reset="placeGroupSelected({})"
          />
        </b-col>
        <b-col cols="5">
          <dynamic-searchable-drop-down
            ref="place_dropdow"
            :clear-able="clearStatus"
            :accent-sensitive="false"
            :api-url="placeApiPath"
            :char-length-threshold="placeThreshold"
            field-to-display="relationSelectCaption"
            place-holder="Localidad"
            :wildcard="wildcard"
            @update-wildcard="wildcard = $event"
            @on-item-selected="placeSelected($event)"
            @on-item-reset="placeSelected({})"
            @select-default-result="selectDefaultResult"
          />
        </b-col>
        <b-col>
          <div
            aria-label="..."
            class="btn-group"
            role="group"
          >
            <clear-button

              @on-clear-button-click="clear()"
            />
            <search-button
              :search-status="loading"
              @on-search-button-click="search()"
            />
          </div>
        </b-col>

      </b-row>
    </b-container>
    <!-- ------------- /search elements container ----- -->

    <!-- ------------- update app banner ----- -->
    <b-alert
      v-model="prompt"
      :variant="promptVariant"
      dismissible
      @dismissed="promptDismissed"
    >
      <span v-if="promptMessage !== 'update-button'">
        {{ promptMessage }}
      </span>
      <update-button
        v-if="promptMessage === 'update-button'"
        @update-app="update"
      />
    </b-alert>
    <!-- ------------- /update app banner ----- -->

    <!-- ------------- app main container ----- -->
    <b-container fluid>


      <!-- ------------- supervisors banners container ----- -->
      <b-row
        v-if="tanatories.supervisors && tanatories.supervisors.length > 0"
        class="mt-3 mb-5"
      >
        <!-- ------------- supervisors cards wrapper ----- -->
        <b-col
          v-for="supervisor in tanatories.supervisors"
          :key="supervisor.supervisor.id"
          cols="12"
        >
          <supervisor-card :supervisor="supervisor.supervisor" />
        </b-col>
        <!-- ------------- /supervisors cards wrapper ----- -->

      </b-row>
      <!-- ------------- /supervisors banners container ----- -->


      <!-- ------------- NO supervisors banner container ----- -->
      <b-row
        v-else-if=" tanatories.supervisors && tanatories.supervisors.length < 1"
        class="mt-3 mb-5"
      >
        <b-col cols="12">
          <b-card
            bg-variant="warning"
            class="rounded-0 text-center"
            fluid
          >
            <b-card-text>Supervisor:</b-card-text>
            <b-card-title>SIN SUPERVISOR ASIGNADO</b-card-title>
          </b-card>
        </b-col>
      </b-row>
      <!-- ------------- /NO supervisors banner container ----- -->

      <!-- ------------- tanatories banner container ----- -->
      <b-row
        v-if="tanatories.tanatories && tanatories.tanatories.length > 0"
        align-h="center"
      >
        <!-- ------------- tanatories carc wrapper ----- -->
        <b-col
          v-for="(item, index) in tanatories.tanatories"
          :key="item.funerary_name"
          class="mb-3 border-secondary"
          cols="12"
          md="4"
          sm="6"
          xl="3"
        >
          <tanatory-card
            :contact="item.contact"
            :funerary="item.funerary"
            :result-index="index"
            :tanatory="item.tanatory"
          />
        </b-col>
        <!-- ------------- /tanatories carc wrapper ----- -->
      </b-row>
      <!-- ------------- /tanatories banner container ----- -->
    </b-container>
    <!-- ------------- /app main container ----- -->
  </div>
</template>


<script>

//import Home from './components/Home.vue'

import UpdateButton from "./components/UpdateAppButton";
import SupervisorCard from "@/components/SupervisorCard";
import TanatoryCard from "@/components/TanatoryCard";
import DynamicSearchableDropDown from "@/components/DynamicSearchableDropDown";
import SearchButton from "@/components/Felberg/SearchButton";
import checkNested from "@/helpers/checkNested";
import dataSetToEmailsAndPhones from "@/helpers/filterAndConcatJsonValues";
import ClearButton from "@/components/Felberg/ClearButton";

import HeaderApp from "@/components/HeaderApp.vue"

import apiFelberg from "@/api";

export default {
  name: 'App',
  components: {
    DynamicSearchableDropDown,
    UpdateButton,
    ClearButton,
    SupervisorCard,
    TanatoryCard,
    SearchButton,
    HeaderApp
  },


  data() {
    return {
      /* eslint-disable */
      apiBasePath: process.env.VUE_APP_BASE_API_PATH,
      /* eslint-enable */

      clearStatus:false,
      ableToClear:false,
      visible: true,
      prompt: false,
      promptVariant: "warning",
      promptMessage: "update-button",
      promptError: true,
      loading: false,
      placeGroup: {},
      place: {},

      tanatories: {},
      wildcard: false
    };
  },
  computed: {
    placeGroupApiPath: function () {
      return this.apiBasePath + "/place-groups/search";
    },
    placeApiPath() {
      const wildcard = this.wildcard ? '/_' : ''
      if (this.checkNested(this.placeGroup, 'id')) {
        return this.apiBasePath + "/places/with-place-group/" + this.placeGroup.id + "/search" + wildcard;
      } else {
        return this.apiBasePath + "/places/search";
      }
    },
    tanatoriesApiPath: function () {
      if (this.checkNested(this.place, 'id')) {
        return this.apiBasePath + "/tanatories/with-place/" + this.place.id;
      } else {
        return "";
      }
    },
    placeThreshold: function () {
      if (this.checkNested(this.placeGroup, 'id')) {
        return 1;
      } else {
        return 3
      }

    }
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.prompt = true;
      });
    }
  },

  methods: {
    selectDefaultResult(item){
      this.placeSelected(item)
      this.search()
    },
    testfunction(value) {
      return "test" + value;
    },
    showAlert(message, variant) {
      this.promptMessage = message;
      this.prontVariant = variant;
      this.prompt = true;
    },
    promptDismissed() {
      this.promptMessage = "";
      this.prontVariant = "info";
      this.prompt = false;
    },
    async update() {

      this.prompt = false
      await this.$workbox.messageSW({type: "SKIP_WAITING"});
    },
    placeSelected(event) {
      //this.$root.$emit('bv::show::collapse', 'nav-collapse')

      if(event.place_group){
        this.placeGroup = event.place_group;
      }

      this.place = event;
      this.clearStatus=false;
      this.wildcard = false
    },
    placeGroupSelected(event) {
      this.$root.$emit('bv::show::collapse', 'nav-collapse')
      this.visible = true
      this.$refs.place_dropdow.resetSelection();
      this.placeGroup = event;
      this.clearStatus=false;

      if(Object.keys(event).length) {
        this.wildcard = true
        setTimeout(() =>{
          this.$refs.place_dropdow.getList()
        },1000)
      } else {
        this.wildcard = false
      }
    },
    async getTanatories() {
      try {
        return apiFelberg.post(this.tanatoriesApiPath)
      } catch (error) {
        console.error('request to: ' + this.tanatoriesApiPath + ' failed', error)
      }
    },
    clear(){

      this.tanatories={};

      this.ableToClear=false;
      this.clearStatus=true;
      this.wildcard = false
    },
    search() {
      if (
        this.placeGroup.id &&
        this.place.id

      ) {
        this.clearStatus=false;
        this.loading = true
        this.getTanatories()
          .then(response => {
            this.loading = false;
            this.tanatories = {};
            this.tanatories = response.data.data;

          })
          .catch(error => {
            this.loading = false;
            console.error(error)
            this.showAlert("No hay resultados que coincidan con la busqueda", "warning")
          })

      }
    },
    checkNested,
    dataSetToEmailsAndPhones,

  }
}

</script>

<style lang="scss">
@import "./scss/style.scss";
</style>
<style>
.search-form {
  margin-left: auto;
}

.article {
  width: 33%;
  float: left;
  padding: 2px;
  box-sizing: border-box;
  background-clip: content-box;
  text-align: center;
}
</style>